import Checkbox from "components/Checkbox/checkbox";
import React, { useState } from "react";
import styles from "./question.module.scss";
import Radio from "components/Radio/radio";
import TextArea from "antd/es/input/TextArea";
import { Rate } from "antd";
import { Height, Widgets } from "@mui/icons-material";




const Question = ({ blockIndex, questionData, questionType, lang, answers, setAnswer, questionIndexInBlock }) => {
  function addAnswer(answerSended) {
    let answerArray = answers
    answerArray.answers[blockIndex].answer[questionIndexInBlock].answer = answerSended
    setAnswer(answerArray)
  }

  const [npsSelected, setNpsSelected] = useState(0);



  function npsText() {
    switch (lang) {
      case "pt":
        return (
          <span className={styles.npsHeader} style={{}}>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}  Em uma escala de 0 a 10, o quanto você indicaria {questionData.subject[lang]}{" "}
            para um amigo ou colega?</span>
        )
      case "en":
        return (
          <span className={styles.npsHeader} style={{}}>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}   How likely is it that you would recommend {questionData.subject[lang]}{" "} to a friend or colleague?</span>
        )
      case "es":
        return (
          <span className={styles.npsHeader} style={{}}>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}   ¿Qué tan probable es que recomiendes {questionData.subject[lang]}{" "} a colegas y amigos?</span>
        )
    }
  }

  switch (questionType) {
    case "multiple-choice":
      let choicesList = answers.answers[blockIndex].answer[questionIndexInBlock].answer
      choicesList = []
      return (
        <div className={styles.questionContainer}>
          <span>
            <div className={styles.questTitle} style={{}}>
              <span >{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""} </span>
              {questionData.header[lang]}
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
            </div>
          </span>
          {questionData.alternatives.map((alternative) => (
            <div className={styles.multipleChoiceText}>
              <Checkbox style={{ height: '1.25rem', width: '1.25rem', marginRight: '0.75rem' }} onClick={(event) => {
                if (event.target.checked == true) {
                  choicesList.push(alternative.id)
                  addAnswer(choicesList)
                } else {
                  choicesList.splice(choicesList.indexOf(alternative.id), 1)
                  addAnswer(choicesList)
                }

              }} />
              {alternative[lang]}
              <br />
            </div>
          ))}
        </div>
      );
    case "single-choice":
      return (
        <div className={styles.questionContainer}>
          {console.log(questionData)}
          <span>
            <div className={styles.questTitle} style={{}}>
              <span>
              {questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}
                </span>
              {questionData.header[lang]}
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
            </div>
          </span>
          <div className={styles.questionText}>
            {questionData.alternatives.map((alternative) => (
              <>
                <div className={styles.radioText} >
                  <Radio onClick={() => addAnswer(alternative.id)} className={styles.radioDefault} />
                  {alternative[lang]}
                </div>
                <br />
              </>
            ))}
          </div>
        </div>
      );
    case "rating-scale":
      return (
        <div className={styles.questionContainer}>
          {console.log(questionData)}
          <div className={styles.questTitle} style={{}}>
            <span>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""} </span>
            <span>{questionData.header[lang]}
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
            </span>
          </div>
          <div className={styles.rateQuestion}>
            <Rate onChange={(event) => addAnswer(event)} />
          </div>
        </div>
      );
    case "nps":
      return (
        <div className={styles.questionContainer}>
          <p style={{}}>
            {
              questionData.required == true &&
              <span style={{ color: 'red', fontWeight: 700 }}>*</span>
            }
          </p>
          <p className={styles.npsText}>
            {npsText()}
          </p>
          <div className={styles.npsForm}>
            <div className={npsSelected === 1 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"1"} >
                <span>1</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="1"
                value="1"
                onChange={() => { addAnswer(1); setNpsSelected(1) }}
              />
            </div>
            <div className={npsSelected === 2 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"2"} >
                <span>2</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="2"
                value="2"
                onChange={() => { addAnswer(2); setNpsSelected(2) }}
              />
            </div>
            <div className={npsSelected === 3 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"3"} >
                <span>3</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="3"
                value="3"
                onChange={() => { addAnswer(3); setNpsSelected(3) }}
              />
            </div>
            <div className={npsSelected === 4 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"4"} >
                <span>4</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="4"
                value="4"
                onChange={() => { addAnswer(4); setNpsSelected(4) }}
              />
            </div>
            <div className={npsSelected === 5 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"5"} >
                <span>5</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="5"
                value="5"
                onChange={() => { addAnswer(5); setNpsSelected(5) }}
              />
            </div>
            <div className={npsSelected === 6 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"6"} >
                <span>6</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="6"
                value="6"
                onChange={() => { addAnswer(6); setNpsSelected(6) }}
              />
            </div>
            <div className={npsSelected === 7 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"7"} >
                <span>7</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="7"
                value="7"
                onChange={() => { addAnswer(7); setNpsSelected(7) }}
              />
            </div>
            <div className={npsSelected === 8 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"8"} >
                <span>8</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="8"
                value="8"
                onChange={() => { addAnswer(8); setNpsSelected(8) }}
              />
            </div>
            <div className={npsSelected === 9 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"9"} >
                <span>9</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="9"
                value="9"
                onChange={() => { addAnswer(9); setNpsSelected(9) }}
              />
            </div>
            <div className={npsSelected === 10 ? styles.npsSquareSelected : styles.npsSquare} >
              <label htmlFor={"10"} className={styles.npsLabel}>
                <span>10</span>
              </label>
              <input
                type="radio"
                className={styles.npsRadio}
                name="nps"
                id="10"
                value="10"
                onChange={() => { addAnswer(10); setNpsSelected(10) }}
              />
            </div>
          </div>
        </div>
      );
    case "text-area":
      return (
        <div className={styles.questionContainer}>
          <div className={styles.questTitle} >
            <span>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}</span>
            <span>{questionData.header[lang]}
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
            </span>
          </div>
          <div>
            <TextArea autoSize={{
          minRows: 3,
        }} size="large"
        style={{fontSize: "24px"}} onChange={(event) => addAnswer(event.target.value)} />
          </div>
        </div>
      );
    case "label":
      return (
        <div className={styles.questionContainer}>
          <div className={styles.labelQuestion} style={{ textTransform: 'capitalize' }}>
            <span>
              {questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}
              {questionData.header[lang]}
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
            </span>
          </div>
        </div>
      );
    default:
      <></>
  }
};

export default Question;
